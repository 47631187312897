import Script from "next/script";

export default function GoogleAdsScript() {
  /* Add google analytics script if env is prod */
  if (process.env.NODE_ENV === "production") {
    return (
      <>
        <Script
          strategy="afterInteractive"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5193931972674404"
          crossOrigin="anonymous"
        />
        {/*         <Script
          id="adsbygoogle-init"
          strategy="afterInteractive"
          async
          dangerouslySetInnerHTML={{
            __html: `
          (adsbygoogle = window.adsbygoogle || []).push({
            google_ad_client: 'ca-pub-5193931972674404',
            enable_page_level_ads: true
       });
          `,
          }}
        /> */}
      </>
    );
  } else {
    return null;
  }
}
